import { TransformTag } from '@/utils'
import API from '@/api'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)

      if (!data) return
    }

    return this.$path(path, data)
  }
  return [
    {
      condition: get('media.cover.length'),
      component: 'Section',
      props: {
        width: 'full',
        style: 'padding:0;',
      },

      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
          },
        ],
      },
    },
    {
      condition: get('content'),
      component: 'Section',
      props: {
        width: 'small',
        style: 'padding-top: 3rem; padding-bottom: 0rem;',
      },

      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: `${TransformTag(get('content'))}`,
            },
            props: {
              textAlign: 'center',
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('customFields.project-portal.max-items') === 'none' ? false : true,
      props: {
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'Portal',
            variant: 'project',
            props: {
              api: API,
              filterMethod: get('customFields.project-portal.filterMethod') || 'county',
              filterCategories:
                get('customFields.project-portal.filterMethod') === 'category'
                  ? get('customFields.project-portal.categories') || []
                  : [],
              theme: get('customFields.project-portal.theme') || 'card',
              maxItems: parseInt(get('customFields.project-portal.max-items')) || 50,
              layout: get('customFields.project-portal.layout'),
              excluded: get('customFields.project-portal.excluded-projects'),
            },
            blocks: {
              top: [
                {
                  condition: get('customFields.project-portal.content'),
                  component: 'Text',
                  data: {
                    content: get('customFields.project-portal.content'),
                  },
                  props: {
                    textAlign: 'center',
                    class: 'text--small',
                    style: 'padding-bottom: 3rem;',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('customFields.contact-cta.content') || get('customFields.contact-cta.button'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },

      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              alignItems: 'center',
              class: 'contact-cta-bottom__content',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  condition: get('customFields.contact-cta.content'),
                  data: {
                    content: get('customFields.contact-cta.content'),
                  },
                  props: {
                    textAlign: 'center',
                  },
                },
                {
                  component: 'Button',
                  condition: get('customFields.contact-cta.button'),
                  data: {
                    label: get('customFields.contact-cta.button'),
                    to: { name: 'Contact' },
                    theme: 'primary',
                    tag: 'router-link',
                    icon: ['fas', 'arrow-right'],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
