<template>
  <Loader
    :key="$path('title', post)"
    :value="promises.fetch"
    class="post-page"
    :class="`post-page--${source}`"
  >
    <Blocks v-if="item.posts" :blocks="config" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BrowserApiMixin from '@/mixins/browser-api'

// pagebuilder
import Config from '@/config/posts'
import { Blocks } from '@kvass/pagebuilder'

export default {
  mixins: [BrowserApiMixin('post')],
  props: {
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Root', ['promises', 'item']),
    config() {
      return Config[this.source].call(this)
    },
    source() {
      return (this.post.customFieldsConfig?.map(schema => schema.source) || ['subpage'])[0]
    },
    post() {
      if (!this.item.posts) return {}

      const post = this.item.posts.find(p => p.slug === this.slug)

      // redirect
      if (!post) {
        this.$router.push({ name: 'Page' })
      }

      return post
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.post-page {
  background-color: var(--background-color);
}
</style>
